import { request, serviceName, oldServiceName } from './base-config';

// AI抓拍问题趋势
export function getAIBoxTrend(data) {
  return request(`${serviceName}/screen/aiBox/aiBoxTrend`, {
    method: 'GET',
    body: data,
  });
}

// AI识别数据
export function getAIBoxCount(data) {
  return request(`${serviceName}/screen/aiBox/count`, {
    method: 'GET',
    body: data,
  });
}

// 违规人数类型分析
export function getAIBoxCountType(data) {
  return request(`${serviceName}/screen/aiBox/countType`, {
    method: 'GET',
    body: data,
  });
}

// 获取抓拍记录
export function getAIBoxRecord(data) {
  return request(`${serviceName}/screen/aiBox/record`, {
    method: 'GET',
    body: data,
  });
}

// 获取摄像头通道号
export function getChannelList(data) {
  return request(`${oldServiceName}/device/aiv/listCodeName`, {
    method: 'GET',
    body: data,
  });
}
