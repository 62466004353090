<template>
  <div>
    <div :class="$style.search">
      <div :class="$style.itemWrap">
        <div :class="$style.item">
          <a-select
            v-model="form.pkId"
            :placeholder="$t('videoMonitor.selectDevice')"
            style="width: 200px;"
            allowClear
            @change="v => handleDeviceChange(v)"
          >
            <a-select-option
              v-for="item in deviceList"
              :key="item.deviceId"
              :value="item.deviceId"
            >
              {{ item.deviceName }}
            </a-select-option>
          </a-select>
          <a-select
            v-model="form.codeName"
            placeholder="请选择摄像头"
            style="width: 200px;"
            allowClear
            @change="handleChannelChange"
          >
            <a-select-option
              v-for="item in channelList"
              :key="item"
              :value="item"
            >
              {{ item }}
            </a-select-option>
          </a-select>
        </div>
        <div :class="$style.item">
          <a-range-picker
            :show-time="{
              defaultValue: [
                $moment('00:00:00', 'HH:mm:ss'),
                $moment('23:59:59', 'HH:mm:ss'),
              ],
            }"
            format="YYYY-MM-DD HH:mm:ss"
            @ok="onChangeTimeRange"
            @change="initData"
            style="width: 4rem;"
          />
        </div>
      </div>
    </div>
    <a-table
      :columns="columns"
      :data-source="tableData"
      :pagination="pagination"
      :loading="loading"
      :scroll="{ x: '100%' }"
      :transformCellText="({ text }) => text || '-'"
      @change="handlePagination"
      :style="selectStyle(tableData)"
    />
  </div>
</template>

<script>
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  getAIBoxRecord,
  getChannelList,
} from '@/services/screen/jiangsu/ai-recognition';
import RecordTable from '@/views/screen/project/video-monitor/components/mixins/more-record';
import { deviceTypeEnum } from '@/enum/device';
import OssImage from '@/views/screen/components/oss-image.vue';

@Component({
  components: {
    OssImage,
  },
})
export default class AIMoreRecord extends mixins(RecordTable) {
  @Prop({ type: Array, default: () => [] }) typeArray;

  get columns() {
    return [
      {
        dataIndex: 'index',
        customRender: (text, row, index) => <span>{index + 1}</span>,
        title: this.$t('safety.index'),
        align: 'center',
        width: 80,
      },
      {
        align: 'left',
        title: '设备名称',
        dataIndex: 'deviceName',
        ellipsis: true,
      },
      // {
      //   align: 'left',
      //   title: '设备码',
      //   dataIndex: 'deviceCode',
      //   ellipsis: true,
      // },
      {
        align: 'left',
        title: '摄像头编号',
        dataIndex: 'code',
        ellipsis: true,
      },
      {
        align: 'left',
        title: '摄像头名称',
        dataIndex: 'cameraName',
        ellipsis: true,
      },
      {
        align: 'left',
        title: '抓拍类型',
        dataIndex: 'alarmType',
        ellipsis: true,
        customRender: txt => this.typeArray[parseInt(txt)],
      },
      {
        align: 'left',
        title: '抓拍人数',
        dataIndex: 'people',
        width: 100,
        ellipsis: true,
      },
      {
        align: 'left',
        title: '抓拍时间',
        dataIndex: 'alarmTime',
        width: 180,
        ellipsis: true,
        customRender: txt => this.dayjs(txt).format('YYYY-MM-DD HH:mm:ss'),
      },
      {
        align: 'left',
        title: this.$t('safetyMonitor.snapPhoto'),
        width: 100,
        dataIndex: 'alarmPhoto',
        customRender: text => (text ? <oss-image ossPath={text} /> : ''),
      },
    ];
  }
  form = {
    pkId: undefined,
    codeName: undefined,
  };
  needGetDeviceList = true;
  deviceGroup = deviceTypeEnum.AIV.deviceGroup;
  handleDeviceChange(v) {
    this.queryParams.current = 1;
    this.form.codeName = undefined;
    this.getTableList(this.form);
    this.getChannels(v);
  }
  getMoreRecordList(queryParams) {
    const data = {
      pkId: this.form.pkId,
      codeName: this.form.codeName,
    };
    return getAIBoxRecord(Object.assign(queryParams, data));
  }
  /** 获取摄像头通道号列表 */
  channelList = [];
  async getChannels(deviceId) {
    const data = {
      idxProjectId: this.projectId,
      pkId: deviceId,
    };
    this.channelList = await getChannelList(data);
  }
  handleChannelChange() {
    this.queryParams.current = 1;
    this.getTableList(this.form);
  }
}
</script>

<style lang="less" module>
.search {
  display: flex;
  justify-content: space-between;
  .itemWrap {
    display: flex;
    margin-bottom: 0.2rem;
  }
  .item {
    margin-right: 0.15rem;
  }
}
</style>
