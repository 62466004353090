<template>
  <div :class="$style.wrapper">
    <div style="grid-area: card_1;">
      <LayoutCard title="AI识别数据">
        <template #action>
          <a-select
            v-model="selectData.dataOne"
            :options="dayList"
            @change="getAIBoxCount"
          />
        </template>
        <div :class="$style.card">
          <BaseWorkerItems :configData="configData" />
        </div>
      </LayoutCard>
    </div>
    <div style="grid-area: card_2;">
      <LayoutCard title="违规人数类型分析">
        <template #action>
          <a-select
            v-model="selectData.dataTwo"
            :options="dayList.slice(1, 3)"
            @change="getAIBoxCountType"
          />
        </template>
        <PieChart
          :chartData="percentChartData"
          :labelOption="labelOption"
          :size="170"
          right="25%"
          left="25%"
        />
      </LayoutCard>
    </div>
    <div style="grid-area: card_3;">
      <LayoutCard title="AI抓拍问题趋势">
        <template #action>
          <a-select
            v-model="selectData.dataThree"
            :options="dayList.slice(1, 3)"
            @change="getAIBoxTrend"
          />
        </template>
        <LineChart
          chart-width="100%"
          chart-height="95%"
          :isSmooth="false"
          symbol="round"
          chartRight="2%"
          chartLeft="4%"
          :unit="['人次']"
          :chart-data="lineChartData"
          :show-legend="true"
        />
      </LayoutCard>
    </div>
    <div style="grid-area: card_4;">
      <LayoutCard>
        <template #header>
          <span style="font-size: 0.17rem;">抓拍数据记录</span>
          <a
            @click="$refs.captureRecord.openDetailModal()"
            :class="$style.detail"
            >详情</a
          >
        </template>
        <CaptureRecord ref="captureRecord" :typeArray="typeArray" />
      </LayoutCard>
    </div>
  </div>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';
import { LayoutCard } from '@/views/screen/layout';
import BaseWorkerItems from '@/views/screen/project/people-management/components/base-worker-items.vue';
import LineChart from '@/views/screen/e-charts/line-chart';
import PieChart from '../../e-charts/pie-chart.vue';
import { nowSize } from '@/utils/common';
import CaptureRecord from './components/capture-record.vue';
import {
  getAIBoxCount,
  getAIBoxCountType,
  getAIBoxTrend,
} from '@/services/screen/jiangsu/ai-recognition';

@Component({
  components: {
    LayoutCard,
    BaseWorkerItems,
    LineChart,
    CaptureRecord,
    PieChart,
  },
})
export default class AiRecognitionIndex extends Vue {
  get projectId() {
    return this.$store.state.screen.projectId;
  }
  mounted() {
    this.getAIBoxCount();
    this.getAIBoxCountType();
    this.getAIBoxTrend();
  }
  dayList = [
    {
      label: this.$t('screen.currentDay'),
      value: 'CURRENT_DAY',
    },
    {
      label: this.$t('screen.sevenDay'),
      value: 'SEVEN_DAY',
    },
    {
      label: this.$t('screen.thirtyDay'),
      value: 'THIRTY_DAY',
    },
  ];
  selectData = {
    dataOne: 'CURRENT_DAY',
    dataTwo: 'SEVEN_DAY',
    dataThree: 'SEVEN_DAY',
  };

  /** AI识别数据 */
  configData = [
    {
      title: '抓拍次数',
      value: 0,
    },
    {
      title: '抓拍人数',
      value: 0,
    },
    {
      title: '累计抓拍次数',
      value: 0,
    },
    {
      title: '累计抓拍人数',
      value: 0,
    },
  ];
  async getAIBoxCount() {
    const params = {
      days: this.selectData.dataOne,
      projectId: this.projectId,
    };
    const {
      currentNumber,
      currentPeople,
      totalNumber,
      totalPeople,
    } = await getAIBoxCount(params);
    this.configData[0].value = currentNumber;
    this.configData[1].value = currentPeople;
    this.configData[2].value = totalNumber;
    this.configData[3].value = totalPeople;
  }

  /** 违规人数类型分析 */
  total = 0;
  percentChartData = [];
  typeArray = [
    '',
    '未佩戴安全帽',
    '明烟明火',
    '未穿反光衣',
    '吸烟行为',
    '人员聚集',
    '黄土未覆盖',
    '区域入侵',
    '打电话',
    '玩手机',
    '车辆冲洗',
  ];
  async getAIBoxCountType() {
    this.total = 0;
    const params = {
      days: this.selectData.dataTwo,
      projectId: this.projectId,
    };
    try {
      const data = await getAIBoxCountType(params);
      this.percentChartData = data.map(item => {
        this.total += Math.abs(item.people);
        return {
          value: item.people,
          name: this.typeArray[parseInt(item.alarmType)],
        };
      });
    } catch {
      this.percentChartData = [];
    }
  }
  get labelOption() {
    return {
      show: true,
      position: 'center',
      formatter: `{a|总}\n{b|${this.total}}`,
      rich: {
        a: {
          color: '#9AC0D9',
          fontSize: nowSize(12),
        },
        b: {
          color: '#fff',
          textShadowColor: '#7DB6FF',
          textShadowBlur: 8,
          fontSize: nowSize(18),
          padding: [nowSize(8), 0, 0, 0],
        },
      },
    };
  }

  /** AI抓拍问题趋势 */
  lineChartData = [];
  async getAIBoxTrend() {
    const params = {
      days: this.selectData.dataThree,
      projectId: this.projectId,
    };
    try {
      const { times, dataList } = await getAIBoxTrend(params);
      if (times.length) {
        this.lineChartData = [
          {
            time: times,
            data: dataList.map(item => ({
              name: item.tag,
              data: item.values.map(e => (e === -1 ? 0 : e)),
            })),
          },
        ];
      } else {
        this.lineChartData = [];
      }
    } catch {
      return false;
    }
  }
}
</script>

<style lang="less" module>
.wrapper {
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0.1rem;
  grid-template-rows: 26% 36% 36%;
  grid-template-areas:
    'card_1 card_4'
    'card_2 card_4'
    'card_3 card_4';
  .card {
    padding: 0.08rem;
    height: 100%;
    width: 100%;
  }
  .detail {
    color: #37d7db;
    margin-left: 0.1rem;
    font-size: 0.17rem;
  }
}
</style>
