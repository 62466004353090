<template>
  <div :class="$style.record">
    <ul :class="$style.lists">
      <li
        :class="$style.item"
        v-for="(item, index) in recordLists"
        :key="index"
      >
        <div :class="$style['item-left']">
          <oss-image
            :ossPath="item.alarmPhoto"
            v-if="item.alarmPhoto"
            :class="$style.img"
          />
          <EmptyContent v-else />
        </div>
        <div :class="$style['item-right']">
          <h3 :class="$style.title">{{ item.code }}</h3>
          <span :class="$style.status">{{ item.cameraName || '--' }}</span>
          <span :class="$style.tip">{{
            typeArray[parseInt(item.alarmType)] || '--'
          }}</span>
          <p :class="$style.time">
            {{ $moment(item.alarmTime).format('YYYY-MM-DD HH:mm:ss') }}
          </p>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { createModal } from '@triascloud/x-components';
import MoreRecord from './more-record.vue';
import { PublicSize } from '@/views/green-construction/components/utils';
import { getAIBoxRecord } from '@/services/screen/jiangsu/ai-recognition';
import OssImage from '@/views/screen/components/oss-image.vue';
import EmptyContent from '@/components/empty-content/empty-content.vue';

@Component({
  components: {
    OssImage,
    EmptyContent,
  },
})
export default class AICaptureRecord extends Vue {
  @Prop({ type: Array, default: () => [] }) typeArray;

  created() {}
  mounted() {
    this.getAIBoxRecord();
  }
  get projectId() {
    return this.$store.state.screen.projectId;
  }
  recordLists = [];
  async getAIBoxRecord() {
    const params = {
      current: 1,
      size: 8,
      idxProjectId: this.projectId,
    };
    try {
      const { records } = await getAIBoxRecord(params);
      this.recordLists = records;
    } catch {
      this.recordLists = [];
    }
  }
  openDetailModal() {
    createModal(() => <MoreRecord typeArray={this.typeArray} />, {
      title: '抓拍数据',
      width: PublicSize.LargeLogModalSize,
      closable: true,
      maskClosable: false,
      wrapClassName: 'screen-modal',
    });
  }
}
</script>
<style lang="less" module>
.record {
  display: flex;
  position: relative;
  padding: 0.08rem;
  & > a {
    position: absolute;
    top: 0;
  }
  .lists {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    padding: 0 0.3rem;
    list-style: none;
    li {
      display: flex;
      margin-top: 0.24rem;
      height: 1.34rem;
      box-sizing: border-box;
      align-items: center;
      .item-left {
        width: 2.04rem;
        height: 1.34rem;
        background: linear-gradient(
          180deg,
          RGBA(11, 29, 53, 1) 9%,
          RGBA(11, 29, 53, 1) 43%,
          RGBA(19, 34, 51, 1) 91%
        );
        .img {
          img {
            width: 2.04rem;
            height: 1.34rem;
            object-fit: cover !important;
          }
        }
      }
      .title {
        font-size: 0.16rem;
        color: #d8e1f3;
      }
      .status {
        font-size: 0.14rem;
        color: #37d7db;
      }
      .tip {
        font-size: 0.14rem;
        color: var(--screen-font-4);
      }
      .time {
        font-size: 0.14rem;
        color: var(--screen-font-1);
      }
      .item-right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 0.24rem;
      }
    }
    li:nth-child(2n) {
      margin-left: auto;
    }
  }
}
</style>
