import { Component, Vue } from 'vue-property-decorator';
import { deviceListById } from '@/services/device-manage/device';
import store from '@/store';
@Component
export default class MoreRecordTable extends Vue {
  mounted() {
    this.getTableList();
    this.getDeviceList();
  }
  get projectId() {
    return store.state.screen.projectId;
  }
  queryParams = {
    idxProjectId: this.projectId,
    startTime: '',
    endTime: '',
    current: 1,
    size: 10,
  };
  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '30', '40'],
    size: 'small',
    showTotal: total => `${total} ${this.$t('common.total')}`,
  };
  tableData = [];
  loading = true;
  async getTableList(data) {
    this.loading = true;
    this.queryParams = Object.assign(this.queryParams, data);
    try {
      const { records, total } = await this.getMoreRecordList(this.queryParams);
      this.tableData = records;
      this.pagination.total = total;
    } finally {
      this.loading = false;
    }
  }
  handlePagination(data) {
    this.pagination = data;
    this.queryParams.current = data.current;
    this.queryParams.size = data.pageSize;
    this.getTableList();
  }
  onChangeTimeRange(time) {
    this.queryParams.startTime = this.$moment(time[0]).format(
      'YYYY-MM-DD HH:mm:ss',
    );
    this.queryParams.endTime = this.$moment(time[1]).format(
      'YYYY-MM-DD HH:mm:ss',
    );
    this.pagination.current = 1;
    this.queryParams.current = 1;
    this.getTableList();
  }
  initData(v) {
    if (v.length === 0) {
      this.queryParams.startTime = '';
      this.queryParams.endTime = '';
      this.pagination.current = 1;
      this.queryParams.current = 1;
      this.getTableList();
    }
  }
  // 获取设备列表
  deviceList = [];
  needGetDeviceList = false;
  async getDeviceList() {
    if (!this.needGetDeviceList) {
      return false;
    }
    try {
      this.deviceList = await deviceListById(this.projectId, this.deviceGroup);
    } catch {
      return false;
    }
  }
  // 表格固定高度
  selectStyle(data) {
    return data.length < 10 ? 'height: 5.5rem;' : 'min-height: 5.5rem;';
  }
}
